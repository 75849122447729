/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import { NextUIProvider } from "@nextui-org/react"

import "@fontsource/bebas-neue"
import "@fontsource/quicksand"
import "@fontsource/noto-sans"
import "./src/styles/globals.css"

import React from "react"
import { navigate } from "gatsby"

const onRedirectCallback = appState => {
  navigate(appState?.returnTo || "/", { replace: true })
}

export const wrapRootElement = ({ element }) => {
  return <NextUIProvider>{element}</NextUIProvider>
}
